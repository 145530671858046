.triangle-wrapper {
  display: flex;
  flex-direction: column;
}

.triangle-wrapper div {
  border: 0px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  align-self: center;
}

.dot {
  height: 40px;
  width: 40px;
  background-color: orange;
  border-radius: 50%;
  margin: 5px;
  display: inline-block;
}

.hitted {
  background-color: grey;
}
